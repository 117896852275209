import styled from 'styled-components';

import { Colors, Spacing } from '@lichtblick/theme';

import { Text } from '../../atoms/Text/Text';

export const Root = styled(Text)<{ $color?: Colors }>`
  color: ${({ $color }) => $color ?? Colors.DarkGray};
  display: flex;
  gap: ${Spacing['3Xs']};

  &:not(:last-child) {
    margin: 0 0 ${Spacing.Xxs};
  }
  sup {
    font-size: 100%;
    min-width: 0.5rem;
  }
`;
