import { FC, type JSX } from 'react';

import { Colors } from '@lichtblick/theme';

import { StyledStatusMessage } from './StatusMessage.styles';
import { MessageStatus, MessageType } from './StatusMessage.types';

import { Text } from '../../atoms/Text/Text';

const statusColorMap: Record<MessageStatus, { backgroundColor: Colors; color: Colors }> = {
  action: {
    backgroundColor: Colors.FadedAqua,
    color: Colors.Aqua,
  },
  error: {
    backgroundColor: Colors.FadedRed,
    color: Colors.Red,
  },
  info: {
    backgroundColor: Colors.BackgroundGray,
    color: Colors.DarkGray,
  },
  success: {
    backgroundColor: Colors.FadedGreen,
    color: Colors.Green,
  },
};

export type StatusMessageProps = {
  icon?: JSX.Element;
  message: string;
  status: MessageStatus;
  type: MessageType;
};

export const StatusMessage: FC<StatusMessageProps> = ({ icon, message, status, type }) => {
  const { backgroundColor, color } = statusColorMap[status];

  return (
    <StyledStatusMessage $backgroundColor={backgroundColor} $color={color} type={type}>
      {icon}
      <Text isBold size="S">
        {message}
      </Text>
    </StyledStatusMessage>
  );
};
