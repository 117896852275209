import styled from 'styled-components';

import { FontWeights } from '@lichtblick/theme';

import { focusRing, linkUnderline, linkUnderlineHover, linkUnderlineThin } from '../shared';

export const Plain = styled.a`
  font-weight: ${FontWeights.Bold};

  &:focus-visible {
    ${focusRing};
  }

  &:active {
    outline: none;
  }

  &[target='_blank']::after {
    content: '↗';
  }
`;

export const Default = styled(Plain)`
  ${linkUnderline};

  &:hover {
    ${linkUnderlineHover};
  }
`;

export const Thin = styled(Plain)`
  font-weight: ${FontWeights.Regular};
  ${linkUnderlineThin};

  &:hover {
    ${linkUnderlineHover};
  }
`;
