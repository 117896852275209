import { FC } from 'react';
import styled, { css } from 'styled-components';

import { GridContainer, GridRow, GridColumn } from './GridContainer';

const DebugContainer = styled.div`
  display: none;
  height: 0;
  pointer-events: none;
  position: sticky;
  top: 0;
  z-index: 998;

  #grid-toggle:checked ~ & {
    display: block;
  }

  span {
    background: rgba(255, 0, 0, 0.1);
    color: #333;
    display: block;
    height: 100vh;
  }
`;

const GridToggle = styled.input`
  opacity: 0;
  position: absolute;
  visibility: hidden;
`;

const Toolbar = styled.div`
  background-color: #fcbc67;
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  left: 0;
  opacity: 0.5;
  padding: 0.25rem 0.5rem 0.5rem;
  position: fixed;
  top: 50%;
  transform: rotate(-90deg) translate(-50%, calc(-100% + 0.5rem));
  transform-origin: 0 0;
  transition:
    opacity 0.25s ease-out,
    transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 9999;

  &:hover {
    opacity: 1;
    transform: rotate(-90deg) translate(-50%, 0);
  }
`;

const toolbarLinkStyles = css`
  border-right: 2px solid #000;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0 0.5rem;

  &:last-child {
    border-right: none;
  }
`;

const ToolbarLink = styled.a`
  ${toolbarLinkStyles}

  &[target='_blank'] span::after {
    content: '\\2197';
  }
`;

const ToolbarLabel = styled.label`
  ${toolbarLinkStyles}
`;

export const GridDebugger: FC = () => (
  <>
    <GridToggle aria-label="Grid anzeigen" id="grid-toggle" type="checkbox" />
    <Toolbar id="grid-toolbar">
      <ToolbarLink href="https://lichtblick-next-storybook.netlify.app/" target="_blank">
        <span>Storybook</span>
      </ToolbarLink>
      <ToolbarLink href="https://lichtblick-email.netlify.app/" target="_blank">
        <span>Email templates</span>
      </ToolbarLink>
      <ToolbarLink href="https://graphdev.lichtblick.de/" target="_blank">
        <span>GraphQL Playground</span>
      </ToolbarLink>
      <ToolbarLabel htmlFor="grid-toggle">
        <span>Toggle grid</span>
      </ToolbarLabel>
    </Toolbar>
    <DebugContainer id="grid-debugger">
      <GridContainer>
        <GridRow>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
          <GridColumn $width={1}>
            <span></span>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </DebugContainer>
  </>
);
