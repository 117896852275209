import styled from 'styled-components';

import { alpha, Colors, DurationAndEasing, Opacities, Spacing } from '@lichtblick/theme';

import { CheckboxState, stateColorMap } from '../../atoms/Checkbox/Checkbox';
import { Label } from '../Label';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioButton = styled.input<{ $state: CheckboxState }>`
  align-items: center;
  appearance: none;
  border: 2px solid ${({ $state }) => stateColorMap[$state]};
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  display: flex;
  flex-shrink: 0;
  height: 1rem;
  justify-content: center;
  margin-top: calc(calc(1.7rem - calc(1rem)) / 2);
  outline: none;
  position: relative;
  width: 1rem;
  z-index: 0;

  /* Focus indicator */
  &::before {
    border: 0.375rem solid ${alpha(Colors.Orange, Opacities.Light)};
    border-radius: 50%;
    content: '';
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity ${DurationAndEasing.SmallIntro};
    width: 100%;
    z-index: -1;
  }

  /* Little "dot" */
  &::after {
    background-color: ${({ $state }) => stateColorMap[$state]};
    border-radius: 50%;
    content: '';
    display: block;
    height: 0.375rem;
    opacity: 0;
    transition: ${DurationAndEasing.SmallIntro};
    width: 0.375rem;
  }

  &:focus-visible::before {
    opacity: 1;
  }

  &:checked {
    background: ${Colors.Orange};

    &::after {
      opacity: 1;
    }
  }
`;

export const RadioLabel = styled(Label)<{ $isDisabled?: boolean }>`
  align-items: flex-start;
  color: ${({ $isDisabled }) => alpha(Colors.Black, $isDisabled ? Opacities.Disabled : 1)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: ${Spacing.Xxs};
`;
