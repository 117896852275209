import { useEffect, useCallback } from 'react';

type UseClickHandlerProps = {
  onPress: () => void;
  ref: React.RefObject<HTMLElement | null>;
};

export const useClickHandler = ({ onPress, ref }: UseClickHandlerProps) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onPress();
      }
    },
    [ref, onPress],
  );

  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onPress();
      }
    },
    [onPress],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleClickOutside, handleEscapeKey]);
};
