'use client';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { FC, PropsWithChildren } from 'react';

import Link from '@lichtblick/link';

import styles from './meta-navigation-link.module.scss';

import { sanitizeSlug } from '../../helpers/sanitizeSlug';

type MetaNavigationLinkProps = PropsWithChildren<{ className?: string; href: string; label: string }>;

export const MetaNavigationLink: FC<MetaNavigationLinkProps> = ({ children, className, href, label }) => {
  const pathname = usePathname();

  const isActive = pathname !== '/' && sanitizeSlug(pathname).startsWith(sanitizeSlug(href));

  return (
    <Link className={classNames(styles.link, isActive && styles['link-active'], className)} href={href} title={label}>
      {children}
      <span>{label}</span>
    </Link>
  );
};
