import styled from 'styled-components';

import { MediaMediumAndAbove } from '@lichtblick/theme';

import { Li } from './Li';

export const Ol = styled.ol`
  list-style: none;
  counter-reset: item;

  ${Li} {
    counter-increment: item;
    padding: 0.7rem 0 0.5rem 1.5rem;

    &::before {
      font-weight: 600;
      content: counter(item) '.';
      display: inline-block;
      font-size: 0.9rem;
      position: absolute;
      right: calc(100% - 0.625rem);
    }

    ${MediaMediumAndAbove} {
      padding: 0.65rem 0 0.5rem 1.5rem;
      &::before {
        font-size: 1rem;
      }
    }
  }
`;
