import classNames from 'classnames';
import { ElementType, FC, PropsWithChildren, type JSX } from 'react';

import styles from './accent-text.module.scss';

export type AccentTextSizeClass = 'm' | 'l';

export type AccentTextProps = PropsWithChildren & {
  className?: string;
  renderAs?: ElementType | keyof JSX.IntrinsicElements;
  /**
   * m = mobile 18px | desktop 20px;
   * l = mobile 25px | desktop 28px;
   */
  size?: AccentTextSizeClass;
};

export const AccentText: FC<AccentTextProps> = ({
  children,
  className,
  renderAs: Tag = 'span',
  size = 'm',
  ...props
}) => (
  <Tag {...props} className={classNames(className, styles['accent-text'], styles[`size-${size}`])}>
    {children}
  </Tag>
);
