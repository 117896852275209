import styled from 'styled-components';

import { Colors } from '@lichtblick/theme';

export const Option = styled.input<{ color: string }>`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 2.5rem;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  margin: 0;
  width: 2.5rem;

  &:disabled {
    cursor: initial;
    opacity: 0.5;
    pointer-events: none;
  }

  &:checked {
    border-color: ${Colors.Black};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: 2px solid ${Colors.Orange};
  }

  &::after {
    background-color: ${({ color }) => color};
    border: 2px solid ${Colors.Gray};
    border-radius: 2rem;
    content: '';
    display: block;
    height: calc(1.5rem - 2px);
    width: calc(1.5rem - 2px);
  }
`;
