import { Content, Overlay, Portal, Root, Trigger } from '@radix-ui/react-dialog';
import styled, { keyframes } from 'styled-components';

import { alpha, BorderRadius, Colors, DurationAndEasing, MediaSmallAndAbove, Spacing, ZIndex } from '@lichtblick/theme';

export const ModalRoot = styled(Root)``;

export const ModalTrigger = styled(Trigger)``;

const animationProperties = {
  overlay: {
    in: keyframes`
      0% { opacity: 0 }
      100% { opacity: 1 }
    `,
    out: keyframes`
      0% { opacity: 1 }
      100% { opacity: 0 }
    `,
  },
  contentMobile: {
    in: keyframes`
      0% {
        opacity: 0;
        transform: translate(-50%, 20%);
      }
      100% {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    `,
    out: keyframes`
      0% {
        opacity: 1;
        transform: translate(-50%, 0);
      }
      100% {
        opacity: 0;
        transform: translate(-50%, 20%);
      }
    `,
  },
  contentDesktop: {
    in: keyframes`
      from { opacity: 0 }
      to { opacity: 1 }
    `,
    out: keyframes`
      from { opacity: 1 }
      to { opacity: 0 }
    `,
  },
} as const;

export const ModalPortal = styled(Portal)``;

export const ModalOverlay = styled(Overlay)`
  background-color: ${alpha(Colors.Black, 0.8)};
  inset: 0;
  position: fixed;
  z-index: ${ZIndex.Modal};

  &[data-state='open'] {
    animation: ${DurationAndEasing.LargeOutro} ${animationProperties.overlay.in};
  }

  &[data-state='closed'] {
    animation: ${DurationAndEasing.LargeOutro} ${animationProperties.overlay.out};
  }
`;

export const ModalSurface = styled(Content)`
  background-color: ${Colors.White};
  border-radius: ${BorderRadius.M} ${BorderRadius.M} 0 0;
  bottom: 0;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 0.625rem 2.375rem -0.625rem,
    hsl(206 22% 7% / 20%) 0 0.625rem 1.25rem -1rem;
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: 80vh;
  overflow: hidden;
  position: fixed;
  transform: translate(-50%, 0);
  width: 100vw;
  z-index: ${ZIndex.Modal};

  &:focus {
    outline: none;
  }

  &[data-state='open'] {
    animation: ${DurationAndEasing.LargeOutro} ${animationProperties.contentMobile.in};
  }

  &[data-state='closed'] {
    animation: ${DurationAndEasing.LargeOutro} ${animationProperties.contentMobile.out};
  }

  ${MediaSmallAndAbove} {
    border-bottom-left-radius: ${BorderRadius.M};
    border-bottom-right-radius: ${BorderRadius.M};
    bottom: unset;
    max-width: 60.6665rem;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;

    &[data-state='open'] {
      animation: ${DurationAndEasing.LargeOutro} ${animationProperties.contentDesktop.in};
    }
    &[data-state='closed'] {
      animation: ${DurationAndEasing.LargeOutro} ${animationProperties.contentDesktop.out};
    }
  }
`;

export const ModalContentWrapper = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 0.375rem;
    width: 0.375rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.Gray};
    border-radius: ${BorderRadius.Xxs};
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const ModalContent = styled.div`
  padding: 1.5rem 1rem 0;

  ${MediaSmallAndAbove} {
    padding: 4rem 4.5rem 0;
  }
`;

export const BottomShadowCurtain = styled.div`
  background: ${Colors.White};
  background: linear-gradient(0deg, ${Colors.White} 0%, rgba(255, 255, 255, 0) 100%);
  display: block;
  flex-shrink: 0;
  height: 3rem;
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const ModalFooter = styled.footer`
  align-items: center;
  background-color: ${Colors.White};
  border-top: 1px solid ${Colors.LightGray};
  box-shadow:
    ${alpha(Colors.Black, 0.08)} 0px 0px 0.5rem,
    ${alpha(Colors.Black, 0.08)} 0px -1rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};
  justify-content: center;
  padding: ${Spacing.Xs};
  width: 100%;

  ${MediaSmallAndAbove} {
    border-bottom-left-radius: ${BorderRadius.M};
    border-bottom-right-radius: ${BorderRadius.M};
    flex-direction: row-reverse;
    padding: ${Spacing.S};
    gap: ${Spacing.Xl};
  }
`;
