import { FC } from 'react';
import styled from 'styled-components';

import { BorderWidth, Colors } from '@lichtblick/theme';

const StrokeLeftWrapper = styled.svg.attrs({
  height: 6,
  viewBox: '0 0 42 6',
  width: 42,
  xmlns: 'http://www.w3.org/2000/svg',
})`
  fill: none;
  left: 0.3125rem;
  position: absolute;
  top: 0.5625rem;
  z-index: 2;
`;

const StrokeRightWrapper = styled.svg.attrs({
  height: 2,
  viewBox: '0 0 15 2',
  width: 15,
  xmlns: 'http://www.w3.org/2000/svg',
})`
  fill: none;
  position: absolute;
  right: 0.625rem;
  top: 0.25rem;
  z-index: 2;
`;

const Stroke = styled.path`
  stroke: ${Colors.Black};
  stroke-linecap: round;
  stroke-width: ${BorderWidth.Input};
`;

export const Wrapper = styled.div`
  height: 1.25rem;
  position: relative;
  width: 100%;
`;

export const Frame = styled.div`
  border: ${BorderWidth.Input} solid ${Colors.Black};
  border-radius: 1.625rem;
  height: 1.25rem;
  position: absolute;
  width: 100%;
  z-index: 2;
`;

export const ProgressContainer = styled.div`
  height: 1.25rem;
  position: relative;
  width: calc(100% - 1.25rem);
`;

export const Progress = styled.div<{ $color: Colors; $percentage: number }>`
  background-color: ${({ $color }) => $color};
  border: ${({ $color }) => `0.625rem solid ${$color}`};
  border-radius: 1.625rem;
  box-sizing: content-box;
  height: 0;
  left: -0.125rem;
  position: absolute;
  top: 0.3125rem;
  transition: width 0.75s linear;
  width: ${({ $percentage }) => Math.min($percentage, 100)}%;
  will-change: width;
  z-index: 1;
`;

export const StrokeLeft: FC = () => (
  <StrokeLeftWrapper>
    <Stroke d="M1 1V1C1 3.20914 2.79086 5 5 5H27" />
    <Stroke d="M41 5L33 5" />
  </StrokeLeftWrapper>
);

export const StrokeRight: FC = () => (
  <StrokeRightWrapper>
    <Stroke d="M14 0.999999L8 1" />
    <Stroke d="M3 1L1 1" />
  </StrokeRightWrapper>
);
