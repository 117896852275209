'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

export const useNavigationState = () => {
  const pathname = usePathname();
  const [activeLevel, setActiveLevel] = useState<number>(-1);

  const setNavigationLevel = (level: number) => {
    setActiveLevel((prev) => (prev === level ? -1 : level));
  };

  useEffect(() => {
    setActiveLevel(-1);
  }, [pathname]);

  return { activeLevel, setNavigationLevel };
};
