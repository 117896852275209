import styled from 'styled-components';

import { BorderRadius, Colors, Spacing } from '@lichtblick/theme';

import { Text } from '../Text';

export const StyledChip = styled.span<{ $background?: string }>`
  background-color: ${({ $background, theme }) => $background ?? (theme.primary || Colors.Black)};
  border-radius: ${BorderRadius.S};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  display: inline-block;
  padding: ${Spacing['3Xs']} ${Spacing.Xxs};
`;

export const Label = styled(Text).attrs({ size: 'Xs' })<{ $color?: string }>`
  color: ${({ $color, theme }) => $color ?? (theme.contrast || Colors.White)};
`;
