import styled from 'styled-components';

import { Icon as IconBase } from '@lichtblick/icons';
import { PickTransient } from '@lichtblick/styled';
import { BorderRadius, Colors, MediaSmallAndAbove, placeholderStyles } from '@lichtblick/theme';

import { MediaCardProps, ResponsiveColumnProps } from './MediaCard';

export const MediaCardGroupRoot = styled.div<PickTransient<ResponsiveColumnProps, 'desktopColumns' | 'mobileColumns'>>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(${({ $mobileColumns }) => $mobileColumns}, 1fr);

  ${MediaSmallAndAbove} {
    grid-template-columns: repeat(${({ $desktopColumns }) => $desktopColumns}, 1fr);
  }
`;

export const MediaCardRoot = styled.label<PickTransient<MediaCardProps, 'isActive'>>`
  background: ${Colors.White};
  border: 2px solid ${({ $isActive }) => ($isActive ? Colors.Black : Colors.LightGray)};
  border-radius: ${BorderRadius.S};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  > img {
    height: auto;
    ${placeholderStyles()}
    width: 100%;
  }
`;

export const Icon = styled(IconBase)`
  margin: 0 auto;
`;
