import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { BorderRadius, BorderWidth, Colors, DurationAndEasing, MediaBelowSmall, Spacing } from '@lichtblick/theme';

export const Root = styled.div<{ $gap?: Spacing }>`
  display: flex;
  flex-direction: column;
  ${({ $gap }) => ($gap ? `gap: ${$gap};` : '')}
`;

export const TabListContainer = styled.nav`
  align-self: center;
  display: flex;
`;

export type MobileNavAlignment = 'row' | 'column';

const columnNavStyle = css`
  ${MediaBelowSmall} {
    border-radius: ${BorderRadius.S};
    flex-direction: column;
  }
`;

export const TabList = styled.ul<{ $backgroundColor: Colors; $mobileNavAlignment: MobileNavAlignment }>`
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  list-style-type: none;
  ${({ $mobileNavAlignment }) => $mobileNavAlignment === 'column' && columnNavStyle}
  padding: ${BorderWidth.Input};
`;

export const TabContainer = styled.li`
  display: flex;
`;

export const Tab = styled.button<{ isActive: boolean }>`
  align-items: center;
  all: unset;
  border-radius: ${BorderRadius.S};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: ${Spacing.Xxs} ${Spacing.Xs};
  position: relative;
  transition: background-color ${DurationAndEasing.SmallIntro};
  z-index: ${({ isActive }) => (isActive ? 0 : 1)};
  &:focus {
    background-color: ${({ isActive }) => (isActive ? 'transparent' : Colors.BackgroundGray)};
  }
`;

export const Background = styled(motion.div)`
  background: ${Colors.Orange};
  border-radius: ${BorderRadius.S};
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
`;
