import { AButtonType, MultiLinkType } from '../types/storyblok';

export const sanitizeStoryblokLink = (href: string | undefined) => {
  if (!href) {
    return undefined;
  }

  if (href.includes(':') || href.startsWith('/') || href.startsWith('#')) {
    return href;
  }

  return `/${href}`;
};

export const buildUrl = (
  entry: Exclude<MultiLinkType, { linktype?: 'email' } | { linktype?: 'asset' }>,
  linkUrl?: string,
): string | undefined => {
  return entry.cached_url ? `${sanitizeStoryblokLink(entry.cached_url)}` : linkUrl;
};

export const getHref = ({ linkAsset, linkEntry, linkUrl }: AButtonType): string =>
  (linkEntry?.cached_url ? `${sanitizeStoryblokLink(linkEntry.cached_url)}${linkUrl ? linkUrl : ''}` : linkUrl) ??
  linkAsset?.filename ??
  '';
