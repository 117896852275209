import styled from 'styled-components';

import { BadgeAppStoreIcon } from '@lichtblick/icons/svg/badge-app-store/badge-app-store';
import { MediaSmallAndAbove } from '@lichtblick/theme';

export const AppleBadge = styled(BadgeAppStoreIcon)`
  height: 2.5rem;

  ${MediaSmallAndAbove} {
    height: 3rem;
  }
`;

export const GoogleBadge = styled.img`
  height: 3.29rem;

  ${MediaSmallAndAbove} {
    height: 3.95rem;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
