import styled, { css, keyframes } from 'styled-components';

import { PickTransient } from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, Opacities, Spacing } from '@lichtblick/theme';

import { LegacyButtonProps } from './LegacyButton';

import { focusRing, linkUnderline, linkUnderlineHover } from '../shared';

export const IconWrapper = styled.span`
  margin-right: ${Spacing.Xxs};
  position: relative;

  & > * {
    vertical-align: middle;
  }
`;

const loadingKeyframes = keyframes`
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
`;

export const Label = styled.span<PickTransient<LegacyButtonProps, 'isLoading'>>`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      &::after {
        animation: ${loadingKeyframes} 1.5s linear infinite;
        content: '';
        display: inline-block;
        text-align: left;
        width: 1rem;
      }
    `}

  position: relative;
`;

const BaseButton = styled.button<PickTransient<LegacyButtonProps, 'isSlim' | 'isWide'>>`
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: ${({ $isSlim }) => ($isSlim ? 0.75 : 1)}rem;
  font-weight: 600;
  line-height: 1.7;
  position: relative;
  text-align: center;
  width: ${({ $isWide }) => ($isWide ? '100%' : 'auto')};

  ${MediaMediumAndAbove} {
    font-size: ${({ $isSlim }) => ($isSlim ? 0.875 : 1.125)}rem;
  }

  &:disabled,
  &[disabled] {
    opacity: ${Opacities.DisabledCustomIcon};
    pointer-events: none;

    &::before {
      filter: grayscale(100%);
      transform: translate(0, 0);
    }
  }
`;

const Button = styled(BaseButton)`
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: ${({ $isSlim }) => ($isSlim ? 0.75 : 1)}rem;
  font-weight: 600;
  flex-grow: 0;
  line-height: 1.7;
  text-align: center;
  width: ${({ $isWide }) => ($isWide ? '100%' : 'auto')};
  border-radius: ${Spacing.L};
  transition: 0.1s;

  ${MediaMediumAndAbove} {
    font-size: ${({ $isSlim }) => ($isSlim ? 0.875 : 1)}rem;
  }

  &:disabled,
  &[disabled] {
    opacity: ${Opacities.DisabledCustomIcon};
    pointer-events: none;

    filter: grayscale(100%);
  }

  outline: none;
  padding: ${({ $isSlim }) => ($isSlim ? '0.25rem 1rem' : '0.5rem 1rem')};

  &:focus-visible {
    ${focusRing};
  }

  &:hover {
    // cannot be animated
    // background-image: linear-gradient(0deg, rgba(0, 0, 0, 11%), rgba(0, 0, 0, 11%));
  }

  &:active {
    outline: none;
    transform: scale(0.98);
  }
`;

export const Primary = styled(Button)`
  background-color: ${Colors.Orange};
  color: ${Colors.Black};

  &:hover {
    background-color: color-mix(in srgb, currentColor 11%, ${Colors.Orange});
  }
`;

export const Secondary = styled(Button)`
  border: 1px solid;

  &:hover {
    background-color: color-mix(in srgb, currentColor 11%, transparent);
  }
`;

export const Destructive = styled(Button)`
  background-color: ${Colors.Red};
  color: ${Colors.White};

  &:hover {
    background-color: color-mix(in srgb, currentColor 11%, ${Colors.Red});
  }
`;

export const Link = styled(BaseButton)`
  padding: 0;
  text-align: inherit;
  word-break: break-word;

  & > ${Label} {
    ${linkUnderline};
    padding: 2px 0 1px;

    ${MediaMediumAndAbove} {
      padding: 2px 0;
    }
  }

  &:hover > ${Label} {
    ${linkUnderlineHover};
  }

  &:focus-visible {
    ${focusRing};
  }
`;
