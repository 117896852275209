/**
 * Expose an unstyled but pre-configured accessible accordion from `@radix-ui/react-accordion`.
 * Use `[data-state='open']` to apply (active) state based styles.
 */
import { Content, Header, Item, Root, Trigger } from '@radix-ui/react-accordion';
import styled, { css, keyframes } from 'styled-components';

import { BorderRadius, Colors, DurationAndEasing } from '@lichtblick/theme';

import { focusRing } from '../../atoms/shared/styles';

const slideDown = keyframes`
  from {
    height: 0;
    overflow: hidden;
  }
  to {
    height: var(--radix-accordion-content-height);
    overflow: auto;
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
    overflow: auto;
  }
  to {
    height: 0;
    overflow: hidden;
  }
`;

export const AccordionRoot = styled(Root)``;

export const AccordionItem = styled(Item)`
  &:focus-within {
    position: relative;
    z-index: 1;
    /* Add box-shadow to focus-within if a11y is required */
  }
`;

export const AccordionHeader = styled(Header)``;

export const AccordionTrigger = styled(Trigger)`
  background-color: transparent;
  font-family: inherit;
  width: 100%;
  border-radius: ${BorderRadius.Xs};
  text-align: left;
  color: ${Colors.Black};

  &:focus-visible {
    ${focusRing};
    /* stylelint-disable-next-line meowtec/no-px */
    outline-offset: -1px;
  }
`;

export const AccordionContent = styled(Content)<{ $isAnimationDisabled?: boolean }>`
  padding: 0 !important; /* Do not overwrite. Set padding on children elements. */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  ${({ $isAnimationDisabled }) =>
    !$isAnimationDisabled &&
    css`
      &[data-state='open'] {
        animation: ${slideDown} ${DurationAndEasing.LargeIntro};
      }
      &[data-state='closed'] {
        animation: ${slideUp} ${DurationAndEasing.LargeOutro};
      }
    `};
`;
