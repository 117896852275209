import styled from 'styled-components';

import { PickTransient } from '@lichtblick/styled';
import { fontStyles, FontWeights, MediaDesktop } from '@lichtblick/theme';

import { HeadlineProps } from './Headline';
import { HeadlineSizeClass } from './Headline';

const responsiveFontSizes: {
  desktop: { [K in HeadlineSizeClass]: string };
  mobile: { [K in HeadlineSizeClass]: string };
} = {
  desktop: {
    S: '1.5rem',
    M: '2.25rem',
    L: '4rem',
  },
  mobile: {
    S: '1.25rem',
    M: '1.625rem',
    L: '2rem',
  },
};

const lineHeights: { [K in HeadlineSizeClass]: string } = {
  S: '140%',
  M: '120%',
  L: '110%',
};

export const StyledHeadline = styled.span<PickTransient<HeadlineProps, 'align' | 'size'>>`
  font-family: var(${fontStyles.headlines.variableName}, arial);
  font-feature-settings: normal;
  font-size: ${({ $size = 'M' }) => responsiveFontSizes.mobile[$size]};
  font-weight: ${FontWeights.Regular};
  line-height: ${({ $size = 'M' }) => lineHeights[$size]};
  text-align: ${({ $align }) => $align};

  ${MediaDesktop} {
    font-size: ${({ $size = 'M' }) => responsiveFontSizes.desktop[$size]};
  }
`;
