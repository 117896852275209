import styled, { css } from 'styled-components';

import { BorderRadius, BorderWidth, Colors, DurationAndEasing, Shadows, Spacing, ZIndex } from '@lichtblick/theme';

import { focusRing } from '../../shared';

export const DropDownContainer = styled.div`
  background: ${Colors.White};
  border-radius: ${BorderRadius.S};
  box-shadow: ${Shadows.Medium};
  display: grid;
  grid-template-columns: 100%;
  left: calc(${BorderWidth.Input} * -1);
  margin-top: calc(2 * ${BorderWidth.Input});
  overflow: hidden; // for Safari < 16
  overflow: clip;
  position: absolute;
  right: calc(${BorderWidth.Input} * -1);
  top: 100%;
  transition: all ${DurationAndEasing.LargeOutro};
  z-index: ${ZIndex.Tooltip - 1};
`;

const openDropdownStyles = css`
  grid-template-rows: 1fr;
  opacity: 1;
  pointer-events: all;
`;

const closedDropdownStyles = css`
  grid-template-rows: 0fr;
  opacity: 0;
  pointer-events: none;
`;

export const DropDownRoot = styled.div`
  position: relative;

  & > ${DropDownContainer} {
    ${closedDropdownStyles}
  }

  &[data-open]:focus-within > ${DropDownContainer} {
    ${openDropdownStyles}
  }
`;

export const DropDownContent = styled.div`
  color: ${Colors.Black};
  min-height: 0;
`;

export const Options = styled.ul`
  list-style: none;
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 8%, rgba(0, 0, 0, 1) 92%, rgba(0, 0, 0, 0));
  max-height: 25rem;
  overflow-y: auto;
  padding: ${Spacing.Xxs} 0;
`;

export const Option = styled.li.attrs({ role: 'option', tabIndex: -1 })`
  border-radius: ${BorderRadius.S};
  cursor: pointer;
  display: block;
  margin: 0 ${Spacing.Xs};
  padding: ${Spacing['3Xs']} ${Spacing.Xxs};

  &:not(:first-child) {
    margin-top: calc(2 * ${Spacing['3Xs']} + 1px);
    position: relative;

    &::before {
      border-top: 1px solid ${Colors.LightGray};
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: calc(${Spacing['3Xs']} * -1 - 1px);
    }
  }

  &:hover {
    background-color: ${Colors.FadedGray};
  }

  &:focus {
    color: ${Colors.Black};
    ${focusRing}
    outline-offset: calc(${Spacing['3Xs']} * -1);
  }
`;
export const NoResult = styled.li`
  color: ${Colors.DarkGray};
  margin: 0 ${Spacing.Xxs} !important;
  padding: ${Spacing['3Xs']} ${Spacing.Xxs};
`;
