import styled from 'styled-components';

import { MediaMediumAndAbove } from '@lichtblick/theme';

import { Li } from './Li';

export const Ul = styled.ul`
  list-style: none;

  ${Li} {
    padding: 0.725rem 0 0.5rem 1.5rem;

    &::before {
      font-size: 0.9rem;
      content: '•';
    }

    ${MediaMediumAndAbove} {
      padding: 0.7rem 0 0.5rem 1.5rem;

      &::before {
        font-size: 1rem;
      }
    }
  }

  ${Li} ${Li} {
    &::before {
      content: '◦';
    }
  }

  ${Li} ${Li} ${Li} {
    &::before {
      content: '⁃';
    }
  }

  ${Li} ${Li} ${Li} ${Li} {
    &::before {
      content: '‣';
    }
  }
`;
