import styled, { css } from 'styled-components';

import Link from '@lichtblick/link';
import { BorderRadius, Colors, FontWeights, MediaBelowMedium, MediaBelowSmall, Spacing } from '@lichtblick/theme';

import { focusRing } from '../../atoms/shared/styles';
import { Text } from '../../atoms/Text/Text';

export const StyledNavigationItemList = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;

  &:not(:first-child) {
    border-top: 1px solid ${Colors.FadedGray};
  }
  svg {
    margin: 0 0.75rem 0 ${Spacing.Xs};
  }
`;

export const StyledNavigationLink = styled(Link)<{ $isActive: boolean }>`
  align-items: center;
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: ${Spacing.Xxs} 0;
  padding: ${Spacing['3Xs']} ${Spacing.Xxs};
  position: relative;

  &:hover {
    background-color: ${Colors.FadedGray};
  }

  &:focus-visible {
    ${focusRing}
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${Colors.FadedGray};
    `}
`;

export const StyledNavigationLinkText = styled(Text).attrs({ size: 'L' })`
  font-weight: ${FontWeights.Bold};
  white-space: nowrap;

  /* stylelint-disable-next-line no-descending-specificity */
  ${MediaBelowSmall} {
    font-size: 0.875rem;
  }

  ${MediaBelowMedium} {
    white-space: normal;
  }
`;
