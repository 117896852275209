import styled from 'styled-components';

import { Colors } from '@lichtblick/theme';

import { Textfield } from '../Textfield';
import { InputRow } from '../Textfield/Textfield.styles';

export const StyledStepper = styled(Textfield)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    appearance: textfield;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield;
  }

  ${InputRow} {
    pointer-events: none;
    position: relative;

    & > input {
      pointer-events: all;
      position: relative;
    }
  }
`;

export const StepperButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  pointer-events: all;
  color: ${Colors.Black};

  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }
`;
