import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { FC, PropsWithChildren } from 'react';

import styles from './navigation.module.scss';

import { buildUrl } from '../../helpers/links';
import { sanitizeSlug } from '../../helpers/sanitizeSlug';
import { NavigationGroupType } from '../../types/storyblok';

type LiLevel0Props = PropsWithChildren<{ navigationGroups: NavigationGroupType[] }>;

export const LiLevel0: FC<LiLevel0Props> = ({ children, navigationGroups }) => {
  const pathname = usePathname();

  const isActive = navigationGroups.some((group) =>
    group.items.some((item) => sanitizeSlug(buildUrl(item.link) || '').startsWith(sanitizeSlug(pathname))),
  );

  return (
    <li className={classNames(styles['nav-level-0-list-item'], isActive && styles['nav-level-0-list-item-active'])}>
      {children}
    </li>
  );
};
