import { css, keyframes } from 'styled-components';

import { BorderRadius, Colors } from '../constants';

const PLACEHOLDER_WIDTH = '81.25rem';

const placeholderKeyframes = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: ${PLACEHOLDER_WIDTH} 0;
  }
`;

/**
 * Can be used instead of `placeholderElement` when pseudo elements are not accessible, e.g. in images.
 *
 * The CSS variables `--placeholder-background-color` and `--placeholder--accent-color` override the respective
 * arguments.
 */
export const placeholderStyles = (backgroundColor = Colors.FadedGray, accentColor = Colors.LightGray) => css`
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderKeyframes};
  animation-timing-function: linear;
  background-image: linear-gradient(
    to right,
    var(--placeholder-background-color, ${backgroundColor}) 2%,
    var(--placeholder-accent-color, ${accentColor}) 33%,
    var(--placeholder-background-color, ${backgroundColor}) 64%
  );
  background-size: ${PLACEHOLDER_WIDTH};
  border-radius: ${BorderRadius.S};
`;

/**
 * Should generally be used to show placeholders. Hides the element's content.
 *
 * The CSS variables `--placeholder-background-color`, `--placeholder--accent-color` and `--placeholder-width`
 * override the respective arguments.
 */
export const placeholderElement = (
  backgroundColor = Colors.FadedGray,
  accentColor = Colors.LightGray,
  width = '100%',
) => css`
  color: transparent;
  position: relative;

  &::before {
    content: '';
    inset: 0;
    ${placeholderStyles(backgroundColor, accentColor)};
    position: absolute;
    right: auto;
    width: var(--placeholder-width, ${width});
  }

  & > * {
    visibility: hidden;
  }
`;
