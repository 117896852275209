'use client';

import { useState, useEffect } from 'react';

import { Breakpoints } from '@lichtblick/theme';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth < Breakpoints.Tablet : true,
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${Breakpoints.Tablet - 1}px)`);

    const handleMediaChange = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener('change', handleMediaChange);

    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  return { isMobile };
};
