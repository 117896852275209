import styled from 'styled-components';

import { Text } from '../../atoms/Text/Text';

export const Wrapper = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Legend = styled(Text)`
  font-weight: bold;
  margin-bottom: 0.75rem;
`;
