import styled, { css } from 'styled-components';

import {
  BorderRadius,
  Colors,
  Duration,
  DurationAndEasing,
  LinkUnderlineWidth,
  MediaBelowMedium,
  MediaBelowSmall,
  MediaMediumAndAbove,
  MediaSmallAndAbove,
  Opacities,
  Spacing,
} from '@lichtblick/theme';

import { focusRing } from '../../atoms/shared/styles';
import { StyledText } from '../../atoms/Text/Text.styles';

export const StyledHeaderNavigationList = styled.li<{ $isFlyoutActive: boolean }>`
  display: flex;
  flex-direction: column;
  list-style: none;
  pointer-events: all;

  /* Overlay */
  &:after {
    background-color: ${Colors.Black};
    content: '';
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transition: opacity 500ms 150ms ease-out;
    z-index: -1;
  }

  ${({ $isFlyoutActive }) =>
    $isFlyoutActive &&
    css`
      &:after {
        opacity: ${Opacities.Overlay};
      }
    `}
`;

export const StyledHeaderNavigationButton = styled.button<{ $isActive: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: transparent;
  position: relative;
  padding: 0.375rem 0;

  ${MediaSmallAndAbove} {
    padding: ${Spacing.Xs} 0;
  }

  ${MediaMediumAndAbove} {
    padding: 1.575rem 0;
  }

  &:focus-visible {
    & > * {
      ${focusRing}
    }
  }

  /* Animated overline */
  &::before,
  &::after {
    border-top: ${LinkUnderlineWidth.Default} solid;
    bottom: auto;
    content: '';
    inset: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: left;
    transition-duration: ${Duration.LargeOutro}ms;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
  }

  &::after {
    border-color: ${Colors.Orange};
  }

  :hover {
    &::before {
      border-color: ${Colors.Black};
      transform: scaleX(1);
    }

    &::after {
      transform: scaleX(1);
      transition-delay: 300ms;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &::before {
        z-index: 1;
      }

      &::after {
        transform: scaleX(1);
        transition-duration: 0;
      }
    `}

  ${StyledText} {
    white-space: nowrap;
    color: ${Colors.Black};
    ${MediaBelowSmall} {
      font-size: 0.875rem;
    }

    ${MediaBelowMedium} {
      white-space: normal;
    }
  }
`;

export const StyledFlyOut = styled.menu<{ $isFlyoutActive: boolean }>`
  background-color: ${Colors.White};
  border-radius: 0 0 ${BorderRadius.S} ${BorderRadius.S};
  left: calc(${Spacing.Xs} * -1);
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${Spacing.Xxs} ${Spacing.Xs};
  position: absolute;
  right: calc(${Spacing.Xs} * -1);
  top: 100%;
  transition:
    opacity ${DurationAndEasing.SmallOutro},
    transform ${DurationAndEasing.SmallOutro};
  max-height: calc(100vh - ${Spacing['3Xl']});
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  display: ${({ $isFlyoutActive }) => ($isFlyoutActive ? 'block' : 'none')};
  ${MediaSmallAndAbove} {
    left: auto;
    margin-left: calc(${Spacing.S} * -1);
    min-width: 18rem;
    right: auto;
  }
`;
