import styled, { css } from 'styled-components';

import { Icon as BaseIcon } from '@lichtblick/icons';
import { BorderRadius, BorderWidth, Colors, MediaDesktop, Opacities, Spacing } from '@lichtblick/theme';

import { AccordionTrigger } from '../Accordion';

export const ItemContent = styled.div`
  align-items: center;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: var(--check-group-item-trigger-padding, ${Spacing.Xs});
  width: 100%;

  ${MediaDesktop} {
    padding: var(--check-group-item-trigger-padding, ${Spacing.Xs} ${Spacing.S});
  }
`;

export const Icon = styled(BaseIcon)`
  opacity: var(--check-group-item-trigger-icon-opacity, ${Opacities.DisabledCustomIcon});
`;

export const Wrapper = styled.div<{ $isCollapsible: boolean; $isHighlighted?: boolean }>`
  border: ${BorderWidth.Input} solid var(--check-group-item-border-color, ${Colors.FadedGray});
  &,
  ${ItemContent}, ${AccordionTrigger} {
    border-radius: ${BorderRadius.S};
  }

  ${({ $isCollapsible, $isHighlighted }) =>
    $isHighlighted &&
    css`
      border-color: var(--check-group-item-active-border-color, ${Colors.Black});
      ${AccordionTrigger} {
        border: ${BorderWidth.Input} solid var(--check-group-item-active-border-color, ${Colors.Black});
        margin: -${BorderWidth.Input} -${BorderWidth.Input} 0;
        width: calc(100% + ${BorderWidth.Input} * 2);
      }

      ${ItemContent} {
        background: ${$isCollapsible
          ? `var(--check-group-item-active-background-color, ${Colors.FadedGray})`
          : 'transparent'};
      }

      ${Icon} {
        opacity: 1;
      }
    `}
`;

export const Slot = styled.div`
  align-items: center;
  display: flex;
  pointer-events: none;
`;

export const CollapsibleContent = styled.div`
  padding: var(--check-group-item-content-padding, ${Spacing.Xs});

  ${MediaDesktop} {
    padding: var(--check-group-item-content-padding, 1.25rem);
  }
`;
