'use client';

import { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';

import { PickTransient } from '@lichtblick/styled';
import { Colors, Shades } from '@lichtblick/theme';

import { LazySvgMap } from './lazy-svg-map';

import { IconName } from '../utils/icon-name';

export type IconProps = {
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
  shiftColor?: Colors | Shades | 'transparent';
};

// passing shiftColor to svg based component is causing a console error
const SVGIcon: FC<IconProps & { name: IconName }> = ({ name, shiftColor: _shiftColor, ...props }) => {
  const Component = LazySvgMap[name];

  return Component && <Component {...props} />;
};

export const StyledIcon: FC<PickTransient<IconProps, 'shiftColor'>> = styled(SVGIcon)<
  PickTransient<IconProps, 'shiftColor'>
>`
  /*
    if the icon doesn't have the color just by adding the $color prop,
    you need to style it like on MProductCardTeaser.styles.ts
  */

  path[class*='shift'] {
    fill: ${({ $shiftColor }) => $shiftColor};
  }
`;

export const Icon: FC<IconProps & { name: IconName }> = ({ shiftColor, ...props }) => (
  <StyledIcon {...props} $shiftColor={shiftColor} />
);
