'use client';

import classNames from 'classnames';
import { useRouter } from 'next/navigation';
import { FC } from 'react';

import { SearchIconXs } from '@lichtblick/icons/svg/search/search-xs';

import headerStyles from './header.module.scss';
import { MetaNavigationLink } from './meta-navigation-link';
import styles from './search-button.module.scss';

export const SearchField: FC = () => {
  const router = useRouter();

  return (
    <>
      <MetaNavigationLink className={classNames(headerStyles['search-link'], styles.link)} href="/suche/" label="Suche">
        <SearchIconXs />
      </MetaNavigationLink>
      <form
        action={(formData) => {
          const rawInputValue = formData.get('search') as string;

          // remove leading/trailing and double spaces
          const sanitizedInputValue = rawInputValue.trim().replace(/\s{2,}/g, ' ');

          router.push(`/suche/${encodeURIComponent(sanitizedInputValue)}/`);
        }}
        className={classNames(styles.container, headerStyles['search-link'])}
      >
        <input
          autoComplete="off"
          className={styles.input}
          maxLength={80}
          minLength={3}
          name="search"
          onBlur={(e) => {
            e.currentTarget.value = '';
          }}
          onInvalid={(e) => {
            e.preventDefault();
          }}
          placeholder="Suche"
          type="search"
        />
        <SearchIconXs className={styles.icon} />
      </form>
    </>
  );
};
