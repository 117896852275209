'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { FC, PropsWithChildren, useMemo, useRef } from 'react';

import { BurgerMenuIconS } from '@lichtblick/icons/svg/burger-menu/burger-menu-s';
import { ChevronLeftIconXs } from '@lichtblick/icons/svg/chevron-left/chevron-left-xs';
import { ChevronRightIconXs } from '@lichtblick/icons/svg/chevron-right/chevron-right-xs';
import { CloseIconS } from '@lichtblick/icons/svg/close/close-s';
import Link from '@lichtblick/link';
import { AccentText } from '@lichtblick/photon/atoms/accent-text/accent-text';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { TeaserItem } from '@lichtblick/photon/molecules/teaser-item/teaser-item';

import { LiLevel0 } from './li-level-0';
import { LiLevel1 } from './li-level-1';
import styles from './navigation.module.scss';
import { Search } from './search';
import { useClickHandler } from './use-click-handler';
import { useIsMobile } from './use-mobile';
import { useNavigationState } from './use-navigation-state';

import { buildUrl } from '../../helpers/links';
import { useScrollLock } from '../../hooks/use-scroll-lock';
import { NavigationType } from '../../types/storyblok';

type NavigationListProps = Pick<NavigationType, 'items'> & PropsWithChildren;

export const NavigationList: FC<NavigationListProps> = ({ children, items }) => {
  const { activeLevel, setNavigationLevel } = useNavigationState();
  const { isMobile } = useIsMobile();

  const navClassNames = useMemo(
    () => classNames(styles.nav, activeLevel >= 0 && styles['nav-active'], !isMobile && styles['nav-hidden']),
    [activeLevel, isMobile],
  );

  const navRef = useRef<HTMLElement>(null);
  const scrollWrapperRef = useRef<HTMLDivElement>(null);

  useScrollLock(isMobile && activeLevel >= 0);
  useClickHandler({
    onPress: () => setNavigationLevel(-1),
    ref: navRef,
  });

  return (
    <>
      <button
        aria-expanded={activeLevel >= 0}
        aria-label="Menü öffnen"
        className={styles['burger-menu-button']}
        onClick={() => setNavigationLevel(0)}
      >
        <BurgerMenuIconS aria-hidden />
      </button>

      {/* Navigation root */}
      <nav aria-label="Hauptnavigation" className={navClassNames} ref={navRef}>
        <button
          aria-label="Menü schließen"
          className={styles['nav-close-button']}
          onClick={() => setNavigationLevel(-1)}
        >
          <CloseIconS aria-hidden />
        </button>

        <div className={styles['scroll-wrapper']} ref={scrollWrapperRef}>
          {/* Navigation level 0 */}
          <div className={classNames(styles['nav-level-0'], activeLevel === 0 && styles['nav-level-0-active'])}>
            <Search />
            <ul className={styles['nav-level-0-list']}>
              {items.map((item, index) => (
                // Navigation level 0 list item
                <LiLevel0 key={item._uid} navigationGroups={item.navigationGroups}>
                  <button
                    aria-expanded={activeLevel === index + 1}
                    className={styles['nav-level-0-button']}
                    onClick={() => {
                      setNavigationLevel(index + 1);
                      scrollWrapperRef.current?.scrollTo({ top: 0 });
                    }}
                  >
                    {item.title} <ChevronRightIconXs />
                  </button>

                  {/* Navigation level 1 */}
                  {item.navigationGroups?.length && (
                    <div
                      className={classNames(
                        styles['nav-level-1'],
                        activeLevel === index + 1 && styles['nav-level-1-active'],
                      )}
                    >
                      <div className={styles['nav-level-1-header']}>
                        <button className={styles['nav-level-1-back-button']} onClick={() => setNavigationLevel(0)}>
                          <ChevronLeftIconXs />
                          <AccentText size="m">{item.title}</AccentText>
                        </button>
                        <Text bold className={styles['nav-level-1-title']} size="l">
                          {item.title}
                        </Text>
                      </div>

                      {/* Navigation groups */}
                      <ul
                        className={classNames(
                          styles['nav-groups-list'],
                          styles[`nav-groups-list-columns-${item.navigationGroupColumns}`],
                        )}
                      >
                        {/* Navigation group */}
                        {item.navigationGroups.map((group) => (
                          <li className={styles['nav-groups-list-item']} key={group._uid}>
                            {group.title && (
                              <Text bold className={styles['nav-groups-list-title']} size="m">
                                <span>{group.title}</span>
                              </Text>
                            )}
                            {/* Navigation group list */}
                            <ul>
                              {group.items.map((item) => (
                                // Navigation group list item
                                <LiLevel1 key={item._uid} navigationItem={item}>
                                  {/* Navigation link */}
                                  <Link className={styles['nav-group-list-link']} href={buildUrl(item.link) ?? ''}>
                                    {item.title}
                                  </Link>
                                </LiLevel1>
                              ))}
                            </ul>
                          </li>
                        ))}

                        {/* Additional navigation items group*/}
                        {item.additionalNavigationItems && (
                          <li className={styles['nav-groups-list-item']} key={item._uid}>
                            <Text
                              bold
                              className={classNames(
                                styles['nav-groups-list-title'],
                                styles['nav-groups-list-title-additional-items'],
                              )}
                              size="m"
                            >
                              <span>Weitere Themen</span>
                            </Text>

                            <ul>
                              {item.additionalNavigationItems?.map((item) => (
                                // Additional navigation item
                                <LiLevel1 key={item._uid} navigationItem={item}>
                                  {/* Navigation link */}
                                  <Link className={styles['nav-group-list-link']} href={buildUrl(item.link) ?? ''}>
                                    {item.title}
                                  </Link>
                                </LiLevel1>
                              ))}
                            </ul>
                          </li>
                        )}
                      </ul>

                      {/* Image */}
                      {item.image?.filename && (
                        <div className={styles.image}>
                          <Image alt={item.title} height={600} src={`${item.image.filename}/m/600x600`} width={600} />
                        </div>
                      )}

                      {/* Teaser list */}
                      {!item.image?.filename && Boolean(item?.teaserItems?.length) && (
                        <div
                          className={classNames(
                            styles['teaser-list'],
                            styles[`teaser-list-columns-${item.teaserItemColumns}`],
                          )}
                        >
                          {item.teaserItems?.map((item) => (
                            <TeaserItem
                              className={styles['teaser-list-item']}
                              key={item._uid}
                              link={{ href: buildUrl(item.link), title: item.title }}
                              media={
                                <Image
                                  alt={item.title}
                                  className={styles['teaser-list-item-image']}
                                  height={180}
                                  src={`${item.image.filename}/m/300x180`}
                                  width={300}
                                />
                              }
                              text={item.title}
                              textOutside
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </LiLevel0>
              ))}
            </ul>
            {children}
          </div>
        </div>
      </nav>
    </>
  );
};
