import styled from 'styled-components';

import { Spacing } from '@lichtblick/theme';

import { Option } from './ColorOption.styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  & > ${Option} {
    margin-right: ${Spacing.Xs};

    &:last-of-type {
      margin-right: 0;
    }

    &:focus-visible,
    &:focus {
      outline: none;
    }
  }
`;
