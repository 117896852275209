import { useEffect } from 'react';

export const useScrollLock = (isExpanded: boolean) => {
  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;

    const enableScrollLock = () => {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;

      document.documentElement.style.overflow = 'hidden';
    };

    const disableScrollLock = () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';

      document.documentElement.style.overflow = '';
    };

    if (isExpanded) {
      enableScrollLock();
    } else {
      disableScrollLock();
    }

    return () => {
      disableScrollLock();
    };
  }, [isExpanded]);
};
