import { ElementType, MouseEvent, PropsWithChildren, forwardRef, type JSX } from 'react';
import { IStyledComponent } from 'styled-components';

import { transient } from '@lichtblick/styled';

import { Primary, Secondary, Destructive, Link, Label, IconWrapper } from './LegacyButton.styles';
import { texts } from './LegacyButton.texts';

type ButtonType = 'button' | 'submit' | 'reset';
export type LegacyButtonVariant = 'primary' | 'secondary' | 'link' | 'destructive';

const variantMap: Record<LegacyButtonVariant, IStyledComponent<'web'>> = {
  primary: Primary,
  secondary: Secondary,
  destructive: Destructive,
  link: Link,
};

export type LegacyButtonProps = PropsWithChildren<{
  className?: string;
  /** If `href` is given the component will render an `<a>` instead of a `<button>` */
  href?: string;
  icon?: ElementType;
  id?: string;
  isDisabled?: boolean;
  isDownloadLink?: boolean; // TODO: remove and handle in mapper
  isLoading?: boolean;
  isSlim?: boolean;
  isWide?: boolean;
  onClick?: (event: MouseEvent) => void;
  renderAs?: ElementType | keyof JSX.IntrinsicElements;
  shouldOpenInNewTab?: boolean; // TODO: remove and handle in mapper
  tabIndex?: number;
  type?: ButtonType;
  variant?: LegacyButtonVariant;
}>;

/** @deprecated */
export const LegacyButton = forwardRef<HTMLButtonElement, LegacyButtonProps>(
  (
    {
      children,
      href,
      icon: Icon,
      isDisabled,
      isDownloadLink,
      isLoading,
      isSlim,
      isWide,
      renderAs,
      shouldOpenInNewTab,
      variant = 'primary',
      ...buttonProps
    },
    ref,
  ) => {
    const Variant = variantMap[variant];
    const as = href ? 'a' : renderAs;

    return (
      <Variant
        as={as}
        disabled={isDisabled || isLoading}
        href={href}
        ref={ref}
        {...buttonProps}
        {...transient({ isDownloadLink, isSlim, isWide, shouldOpenInNewTab })}
      >
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
        <Label $isLoading={isLoading}>{isLoading ? texts.label.loading : children}</Label>
      </Variant>
    );
  },
);
