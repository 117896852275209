import { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { SmallAndAbove } from '@lichtblick/theme';

const imageStyle = css`
  max-width: 100%;
  vertical-align: middle;
`;

export const StyledImage = styled.picture`
  ${imageStyle}
`;

const Img = styled.img`
  ${imageStyle}
`;

export type ImageProps = HTMLAttributes<HTMLPictureElement> & {
  description: string;
  url: {
    desktop?: string;
    mobile: string;
  };
};

export const Image: FC<ImageProps> = ({ description, url, ...pictureProps }) => (
  <StyledImage {...pictureProps}>
    {url.desktop && <source media={SmallAndAbove} srcSet={url.desktop} />}
    <Img alt={description} loading="lazy" src={url.mobile} />
  </StyledImage>
);
