import styled, { css } from 'styled-components';

import { Breakpoints, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const ContainerStyle = css`
  margin-inline: auto;
  max-width: var(--max-content-width, ${Breakpoints.Large}px);
  padding-inline: ${Spacing.Xs};

  ${MediaMediumAndAbove} {
    padding-inline: ${Spacing.Xl};
  }
`;
/**
 *  `max-width` is overridden by CSS variable `--max-content-width`.
 */
export const Container = styled.section`
  ${ContainerStyle}
`;
