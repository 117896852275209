import styled, { css } from 'styled-components';

import { Colors, Opacities, Spacing, alpha } from '@lichtblick/theme';

import { Text } from '../Text';

const SwitchHeight = '1.75rem';
const SwitchWidth = '3rem';
const SwitchLineHeight = '1.5rem';

export const ToggleSwitchCheckbox = styled.input.attrs(() => ({ type: 'checkbox' }))`
  opacity: 0;
  position: absolute;
`;

export const ToggleSwitch = styled.div<{ $isChecked?: boolean; $isDisabled?: boolean }>`
  background: ${Colors.Black};
  border-radius: calc(${SwitchHeight} / 2);
  display: inline-block;
  flex-shrink: 0;
  height: ${SwitchHeight};

  ${({ $isChecked }) =>
    $isChecked
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.4;
        `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.2;
      transition: none;
    `}

  position: relative;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  width: ${SwitchWidth};

  &::before {
    background: ${Colors.White};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${SwitchHeight};
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0.85);
    transition: left 0.25s;
    width: ${SwitchHeight};
  }

  ${ToggleSwitchCheckbox}:focus + & {
    box-shadow: 0 0 0 0.25rem ${Colors.Orange};
  }

  ${ToggleSwitchCheckbox}[type='checkbox'][data-checked='true']:focus + & {
    box-shadow: 0 0 0 0.25rem ${alpha(Colors.Orange, 0.4)};
  }

  ${ToggleSwitchCheckbox}[type='checkbox'][data-checked='true'] ~ &::before {
    left: calc(${SwitchWidth} - ${SwitchHeight});
  }
`;

export const ToggleLabel = styled(Text)<{ $isDisabled: boolean }>`
  line-height: ${SwitchLineHeight};
  margin-left: ${Spacing.Xxs};
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: ${Opacities.Light};
    `}
  padding: calc((${SwitchHeight} - ${SwitchLineHeight}) / 2) 0;
  position: relative;
`;

export const ToggleWrapper = styled.label<{ $isDisabled?: boolean }>`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 0.125rem;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: initial;
      pointer-events: none;
    `}

  :focus {
    outline: none;
  }
`;
