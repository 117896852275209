import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { FC, PropsWithChildren } from 'react';

import styles from './navigation.module.scss';

import { buildUrl } from '../../helpers/links';
import { sanitizeSlug } from '../../helpers/sanitizeSlug';
import { NavigationItemType } from '../../types/storyblok';

type LiLevel1Props = PropsWithChildren<{ navigationItem: NavigationItemType }>;

export const LiLevel1: FC<LiLevel1Props> = ({ children, navigationItem }) => {
  const pathname = usePathname();
  const isActive = sanitizeSlug(buildUrl(navigationItem.link) || '') === sanitizeSlug(pathname);

  return (
    <li className={classNames(styles['nav-group-list-item'], isActive && styles['nav-group-list-item-active'])}>
      {children}
    </li>
  );
};
