import { FC, HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode, type JSX } from 'react';

import {
  TextCTAVariant,
  StyledTextCTAIconWrapper,
  StyledTextCTAText,
  StyledTextCTAButton,
  StyledTextCTALink,
} from './TextCTA.styles';

type TextCTABaseBaseProps = {
  className?: string;
  'data-dd-action-name'?: string;
  'data-testid'?: string;
  isInlineLink?: boolean;
  isSlim?: boolean;
  tabIndex?: number;
  variant?: TextCTAVariant;
};

type TextCTAWithChildrenProps = TextCTABaseBaseProps & { ariaLabel?: string; children: ReactNode; icon?: JSX.Element };
type TextCTAWithoutChildrenProps = TextCTABaseBaseProps & { ariaLabel: string; icon: JSX.Element };

type TextCTABaseProps = TextCTAWithChildrenProps | TextCTAWithoutChildrenProps;

export type TextCTAButtonProps = TextCTABaseProps & {
  as: 'button';
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  type?: React.ButtonHTMLAttributes<undefined>['type'];
};

export type TextCTAAnchorProps = TextCTABaseProps & {
  as: 'a';
  href: string;
  isDownload?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  target?: HTMLAttributeAnchorTarget;
};

export type TextCTAProps = TextCTAButtonProps | TextCTAAnchorProps;

const isCTAPropsWithChildren = (props: unknown): props is TextCTAWithChildrenProps =>
  Boolean((props as TextCTAWithChildrenProps)?.children);

export const TextCTA: FC<TextCTAProps> = (props) => {
  const {
    ariaLabel,
    as,
    className,
    'data-dd-action-name': ddAction,
    'data-testid': testId,
    icon,
    isInlineLink = false,
    isSlim,
    tabIndex,
    variant = 'default',
  } = props;
  const children = isCTAPropsWithChildren(props) ? props.children : undefined;

  if (as === 'a') {
    const { href, isDownload = false, onClick, target } = props;

    return (
      <StyledTextCTALink
        $isInlineLink={isInlineLink}
        $isSlim={isSlim}
        $variant={variant}
        aria-label={ariaLabel}
        className={className}
        data-dd-action-name={ddAction}
        data-testid={testId}
        download={isDownload}
        href={href}
        onClick={onClick}
        tabIndex={tabIndex}
        target={target}
      >
        {icon && <StyledTextCTAIconWrapper>{icon}</StyledTextCTAIconWrapper>}
        {children && <StyledTextCTAText>{children}</StyledTextCTAText>}
      </StyledTextCTALink>
    );
  }

  const { isDisabled, isLoading, onClick, type } = props;

  return (
    <StyledTextCTAButton
      $isInlineLink={isInlineLink}
      $isSlim={isSlim}
      $variant={variant}
      aria-label={ariaLabel}
      as="button"
      className={className}
      data-dd-action-name={ddAction}
      data-testid={testId}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type}
    >
      {icon && <StyledTextCTAIconWrapper>{icon}</StyledTextCTAIconWrapper>}
      {children && <StyledTextCTAText>{children}</StyledTextCTAText>}
    </StyledTextCTAButton>
  );
};
