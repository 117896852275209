import styled, { css } from 'styled-components';

import { PickTransient } from '@lichtblick/styled';
import { FontWeights, MediaDesktop, Spacing } from '@lichtblick/theme';

import { TextProps, TextSizeClass } from './Text';

const mobileFontSizes: Record<TextSizeClass, string> = {
  Xs: '0.625rem',
  S: '0.75rem',
  M: '1rem',
  L: '1rem',
};

const desktopFontSizes: Record<TextSizeClass, string> = {
  Xs: '0.75rem',
  S: '0.875rem',
  M: '1rem',
  L: '1.125rem',
};

export const StyledText = styled.span<
  PickTransient<TextProps, 'align' | 'isBold' | 'isItalic' | 'size' | 'hasSpacing'>
>`
  display: block;
  font-size: ${({ $size = 'M' }) => mobileFontSizes[$size]};
  font-style: ${({ $isItalic }) => ($isItalic ? 'italic' : 'normal')};
  font-weight: ${({ $isBold }) => ($isBold ? FontWeights.Bold : FontWeights.Regular)};
  line-height: 170%;
  text-align: ${({ $align }) => $align};
  white-space: pre-wrap;

  ${({ $hasSpacing }) =>
    $hasSpacing &&
    css`
      &:not(:last-child) {
        margin-bottom: ${Spacing.Xs};
      }
    `}

  ${MediaDesktop} {
    font-size: ${({ $size = 'M' }) => desktopFontSizes[$size]};
  }
`;
