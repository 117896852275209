import classNames from 'classnames';
import { ElementType, ReactNode, forwardRef, type JSX } from 'react';

import styles from './text.module.scss';

/**
 * Responsive size classes:
 * https://www.figma.com/file/A0m8tFZk3yHpbiCtmlCXfb/%5BPhoton%5D-Typography-(Web)?node-id=2%3A32&t=jtIHhBKNgXKstYE0-1
 */
export type TextSizeClass = 'xs' | 's' | 'm' | 'l';

export type TextProps = {
  align?: 'left' | 'center' | 'right';
  bold?: boolean;
  children?: ReactNode;
  className?: string;
  id?: string;
  italic?: boolean;
  renderAs?: ElementType | keyof JSX.IntrinsicElements;
  /**
   * XS = mobile 10px | desktop 12px;
   * S = mobile 12px | desktop 14px;
   * M = mobile 16px | desktop 16px;
   * L = mobile 16px | desktop 18px;
   */
  size?: TextSizeClass;
  spacing?: boolean;
};

export const Text = forwardRef<HTMLElement, TextProps>(
  ({ align, bold, children, className, italic, renderAs: Tag = 'span', size = 'm', spacing, ...props }, ref) => (
    <Tag
      {...props}
      className={classNames(
        'text',
        styles.text,
        styles[`size-${size}`],
        align && styles[`align-${align}`],
        bold && styles.bold,
        italic && styles.italic,
        spacing && styles.spacing,
        className,
      )}
      ref={ref}
    >
      {children}
    </Tag>
  ),
);
