export type PickTransient<T, K extends keyof T> = {
  [P in K as P extends string ? `$${P}` : P]: T[P];
};

export const transient = <T extends object>(props: T) =>
  Object.entries(props).reduce((transientProps, [key, value]) => {
    transientProps[`$${key}`] = value;

    return transientProps;
  }, {} as any) as PickTransient<T, keyof T>;
