import styled from 'styled-components';

import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

import { footerCellStyles } from './Footer.styles';

import { StyledHeadline } from '../../atoms/Headline/Headline.styles';

export const StyledPrimaryNavigation = styled.li`
  list-style: none;
  flex: 1;
  margin: 2rem 0;
  ${footerCellStyles};

  ${StyledHeadline} {
    display: inline-block;
    margin-bottom: 1rem;

    ${MediaMediumAndAbove} {
      margin-bottom: 1.5rem;
    }
  }
`;

export const StyledPrimarySubNavigation = styled.menu`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};
`;
