import styled from 'styled-components';

import { DropDown } from '../../atoms/DropDown/DropDown';
import { sharedIconWrapperStyles } from '../FilterSelect/FilterSelect.styles';
import { InputElementProps } from '../Textfield/Textfield';
import {
  SharedInputRowProps,
  getSharedInputRowStyles,
  sharedInputStyles,
  sharedLabelWrapperStyles,
  sharedSlotStyles,
  sharedWrapperStyles,
} from '../Textfield/Textfield.styles';

export const Wrapper = styled.div`
  ${sharedWrapperStyles}
  position: relative;
`;

export const LabelWrapper = styled.div`
  ${sharedLabelWrapperStyles}
`;

export const InputRow = styled(DropDown).attrs({ variant: 'input' })<SharedInputRowProps>`
  ${getSharedInputRowStyles}

  &[data-open]:focus-within {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Slot = styled.div`
  ${sharedSlotStyles}
`;

export const Input = styled.input<InputElementProps>`
  ${sharedInputStyles.base}
  ${sharedInputStyles.hasRightSlot}
`;

// using `<div>` instead of `<button>` to avoid changing `:focus-within` unless `tabIndex` is explicitly set
export const IconWrapper = styled.div.attrs({ 'aria-hidden': true, type: 'button' })`
  ${sharedIconWrapperStyles}
`;
