'use client';

import classNames from 'classnames';
import { useRouter } from 'next/navigation';
import { FC } from 'react';

import { SearchIconXs } from '@lichtblick/icons/svg/search/search-xs';

import styles from './search.module.scss';

export const Search: FC = () => {
  const router = useRouter();

  return (
    <form
      action={(formData) => {
        const rawInputValue = formData.get('search') as string;

        // remove leading/trailing and double spaces
        const sanitizedInputValue = rawInputValue.trim().replace(/\s{2,}/g, ' ');

        router.push(`/suche/${encodeURIComponent(sanitizedInputValue)}/`);
      }}
      className={classNames(styles.container)}
    >
      <input
        autoComplete="off"
        className={styles.input}
        maxLength={80}
        minLength={3}
        name="search"
        onInvalid={(e) => {
          e.preventDefault();
        }}
        placeholder="Suche"
        type="search"
      />
      <button aria-label="Suchen" className={styles.submit} type="submit">
        <SearchIconXs aria-hidden />
      </button>
    </form>
  );
};
